import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getContentType from "../../services/KenticoServices";
import { RootState } from "../store";

export interface contentTypeState {
  main: any;
  header: any;
  footer: any;
  status: "idle" | "loading" | "failed" | "fulfilled";
  isCultureVisible: string;
}

const initialState: contentTypeState = {
  main: {},
  header: {},
  footer: {},
  status: "idle",
  isCultureVisible: "no",
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getKenticoData = createAsyncThunk(
  "contentType/getKenticoData",
  async (params, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const { country, language } = state.culture;
      const landingContentType =
        process.env.REACT_APP_KENTICO_CONTENT_TYPE || "";

      const footerContentType = "footer";
      const headerContentType = "header";

      const kenticoLanding = await getContentType(
        landingContentType,
        `${language}_${country}`
      );

      const kenticoFooter = await getContentType(
        footerContentType,
        `${language}_${country}`
      );

      const kenticoHeader = await getContentType(
        headerContentType,
        `${language}_${country}`
      );

      process.env.REACT_APP_ENV !== "prod" &&
        console.log("Kentico Data desde Redux action: ", kenticoLanding);

      return { kenticoLanding, kenticoFooter, kenticoHeader };
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

export const contentTypeSlice = createSlice({
  name: "contentType",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getKenticoData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getKenticoData.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getKenticoData.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.main = action.payload.kenticoLanding;
        state.footer = action.payload.kenticoFooter;
        state.header = action.payload.kenticoHeader;
        state.isCultureVisible =
          action.payload.kenticoLanding.modular_content[
            action.payload.kenticoLanding.item.elements.publish_options.value[0]
          ].elements.visible.value[0].codename;
      });
  },
});

// export const { getKenticoData } = contentTypeSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectMainContentType = (state: RootState) =>
  state.contentType.main;
export const selectFooterContentType = (state: RootState) =>
  state.contentType.footer;
export const selectHeaderContentType = (state: RootState) =>
  state.contentType.header;
export const selectContentTypeLoadingStatus = (state: RootState) =>
  state.contentType.status;
export const selectIsCultureVisible = (state: RootState) =>
  state.contentType.isCultureVisible;

export default contentTypeSlice.reducer;
