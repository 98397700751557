import ReactHtmlParser from "html-react-parser";

const RRSSLink = ({ href, content }) => {
  return (
    <a href={href} className="d-inline-block mx-1 mx-sm-2">
      {ReactHtmlParser(content)}
    </a>
  );
};

export default RRSSLink;
