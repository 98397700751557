import React from "react";

const PreviousLabel = () => {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.97252 0.0720338C7.25465 0.0720338 7.53677 0.200397 7.73829 0.457124C8.12117 0.906396 8.08087 1.591 7.63753 1.97609L2.57943 6.57578L7.63753 11.1755C8.06072 11.5606 8.12117 12.2452 7.73829 12.6944C7.37556 13.1437 6.7307 13.2079 6.30751 12.8014L0.342579 7.38875C0.141062 7.19621 -4.13809e-07 6.89669 -3.85754e-07 6.57578C-3.57699e-07 6.25487 0.141064 5.95536 0.362734 5.76281L6.30751 0.32876C6.50903 0.157609 6.75085 0.0720338 6.97252 0.0720338Z"
        fill="#BDBDBD"
      />
    </svg>
  );
};

export default PreviousLabel;
