import { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { Invoice } from "../../../@types/Invoice";
import { selectMainContentType } from "../../../redux/contentType/contentTypeSlice";
import { useAppSelector } from "../../../redux/hooks";
import {
  selectInvoiceList,
  selectInvoiceListHasItems,
  selectInvoiceLoadingStatus
} from "../../../redux/invoices/invoicesSlice";
import ExcelExport from "./ExcelExport";
import NextLabel from "./NextLabel";
import PreviousLabel from "./PreviousLabel";
import TableBody from "./TableBody";
import TableHead from "./TableHead";

const Table = () => {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState<Invoice[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const itemsPerPage = Number(process.env.REACT_APP_ITEMS_PER_PAGE) || 30;
  const tableRef = useRef<HTMLTableElement>(null);
  const resultsRef = useRef<HTMLDivElement>(null);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const invoiceList = useAppSelector(selectInvoiceList);

  useEffect(() => {
    // Fetch invoiceList from another resources.
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(invoiceList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(invoiceList.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, invoiceList]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    tableRef?.current?.scrollIntoView();
    const newOffset = (event.selected * itemsPerPage) % invoiceList.length;

    setItemOffset(newOffset);
  };

  const invoiceListHasItems = useAppSelector(selectInvoiceListHasItems);
  const loadingStatus = useAppSelector(selectInvoiceLoadingStatus);
  const {
    item: { elements },
  } = useAppSelector(selectMainContentType);

  useEffect(() => {
    if (loadingStatus === "success") {
      resultsRef?.current?.scrollIntoView({
        block: invoiceListHasItems ? undefined : "center",
      });
    }

    if (loadingStatus === "failed") {
      resultsRef?.current?.scrollIntoView({ block: "center" });
    }
  }, [loadingStatus, invoiceListHasItems]);

  return (
    <div ref={resultsRef}>
      {loadingStatus === "success" ? (
        invoiceListHasItems ? (
          <div className="table-responsive rounded-2">
            <table
              ref={tableRef}
              className="table align-middle text-center"
              id="invoices-table"
            >
              <TableHead />
              <TableBody invoiceList={currentItems} />
            </table>
            <ExcelExport />
            <div className="d-flex justify-content-center mt-4">
              <nav aria-label="Page navigation">
                <ReactPaginate
                  containerClassName="pagination"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  activeClassName="active"
                  breakLabel="..."
                  nextAriaLabel="Next"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  nextLabel={<NextLabel />}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={4}
                  pageCount={pageCount}
                  previousAriaLabel="Previous"
                  previousClassName="page-item "
                  previousLinkClassName="page-link"
                  previousLabel={<PreviousLabel />}
                  renderOnZeroPageCount={() => null}
                />
              </nav>
            </div>
          </div>
        ) : (
          <p className="text-center col-md-6 offset-md-3 p-2 rounded no-data-msg">
            {elements.no_data_msg?.value}
          </p>
        )
      ) : loadingStatus === "failed" ? (
        <p className="text-center col-md-6 offset-md-3 p-2 rounded no-data-msg">
          {elements.no_results_mgs?.value}
        </p>
      ) : null}
    </div>
  );
};

export default Table;
