import { useMemo } from "react";
import {
  selectHeaderContentType,
  selectMainContentType,
} from "../../../redux/contentType/contentTypeSlice";
import { selectCultureStore } from "../../../redux/culture/cultureSlice";
import { useAppSelector } from "../../../redux/hooks";
import "../css/jetsmart_header_corporate.css";
import DropdownFlag from "./DropdownFlag";

const CultureDropdown = () => {
  const mainContentType = useAppSelector(selectMainContentType);
  const headerContentType = useAppSelector(selectHeaderContentType);
  const modular = headerContentType.modular_content;
  const cultureStore = useAppSelector(selectCultureStore);

  const publishOptions: string[] =
    mainContentType.modular_content.publish_options_facturas.elements.culture.value.map(
      ({ name }: { name: string }) => name
    );

  const cultureList = useMemo(() => {
    const getCultureList = () => {
      let temp = [];
      for (const country in modular) {
        if (Object.hasOwnProperty.call(modular, country)) {
          const element = modular[country]["elements"];
          temp.push(element);
        }
      }
      return temp;
    };
    return getCultureList();
  }, [modular]);

  const currentCulture = cultureList.find(
    (item) => item.name.value === cultureStore.country
  );

  const visibleCulturesWithoutCurrent = cultureList.filter(
    (item) =>
      item.name.value !== currentCulture.name.value &&
      publishOptions.includes(item.name.value)
  );
  return (
    <>
      <li className="nav-item dropdown">
        <div
          className="nav-link dropdown-toggle py-2 d-flex align-items-center"
          aria-current="page"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <DropdownFlag country={currentCulture?.name?.value} />
          <span className="d-flex my-2">{currentCulture?.country?.value}</span>
        </div>
        {visibleCulturesWithoutCurrent.length > 0 && (
          <ul className="dropdown-menu" style={{ minWidth: "140px" }}>
            {visibleCulturesWithoutCurrent.map((item) => (
              <li key={item.country.value + "-" + item.name.value}>
                <a
                  className="nav-link py-2 d-flex align-items-center"
                  href={`/${item.name.value}/${item.language.value}/${process.env.REACT_APP_ROUTE_NAME}`}
                >
                  <DropdownFlag country={item?.name?.value} />

                  <span className="d-flex my-2">{item.country.value}</span>
                </a>
              </li>
            ))}
          </ul>
        )}
      </li>
    </>
  );
};

export default CultureDropdown;
