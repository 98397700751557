import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import cultureReducer from "./culture/cultureSlice";
import invoicesReducer from "./invoices/invoicesSlice";
import contentTypeReducer from "./contentType/contentTypeSlice";

export const store = configureStore({
  reducer: {
    culture: cultureReducer,
    invoices: invoicesReducer,
    contentType: contentTypeReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
