import moment from "moment";

import { FormEvent, useState } from "react";

import DatePicker, { registerLocale } from "react-datepicker";
import { selectMainContentType } from "../../../redux/contentType/contentTypeSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  clearFilters,
  fetchInvoiceList,
  selectDocumentTypes,
  selectInvoiceLoadingStatus,
  selectInvoiceState,
  setFechaFin,
  setFechaInicio,
  setInvoiceNumber,
  setTipoDte,
} from "../../../redux/invoices/invoicesSlice";

import en from "date-fns/locale/en-US";
import es from "date-fns/locale/es";
import pt from "date-fns/locale/pt";
import "../css/datepickerjetsmart.css";
import IconNavigationWhite from "./IconNavigationWhite";
import {
  selectCalendarLanguage,
  selectCultureLanguage,
} from "../../../redux/culture/cultureSlice";
import InputSearchIcon from "./InputSearchIcon";
registerLocale("es", es);
registerLocale("pt", pt);
registerLocale("en", en);

const Form = () => {
  const dispatch = useAppDispatch();
  const invoiceState = useAppSelector(selectInvoiceState);
  const [maxDate, setMaxDate] = useState<Date>();

  const [fechaInicioError, setFechaInicioError] = useState<string>("");
  const [fechaFinError, setFechaFinError] = useState<string>("");
  const [tipoDteError, setTipoDteError] = useState<string>("");
  const loadingStatus = useAppSelector(selectInvoiceLoadingStatus);
  const currentDate: Date = new Date();

  const minCalendarDate: Date = moment("2018-01-01").toDate();
  const {
    item: { elements },
  } = useAppSelector(selectMainContentType);

  const documentOptions = useAppSelector(selectDocumentTypes);
  const language = useAppSelector(selectCultureLanguage);
  const calendarLanguage = useAppSelector(selectCalendarLanguage);

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();

    setFechaFinError("");
    setFechaInicioError("");
    setTipoDteError("");

    if (!invoiceState.invoiceNumber) {
      if (
        !invoiceState.fechaFin ||
        !invoiceState.fechaInicio ||
        !invoiceState.tipoDTE
      ) {
        !invoiceState.fechaFin &&
          setFechaFinError(elements.msg_start_end_required.value);
        !invoiceState.fechaInicio &&
          setFechaInicioError(elements.msg_start_date_required.value);
        !invoiceState.tipoDTE &&
          setTipoDteError(elements.msg_document_type_required.value);
        return;
      }
    }

    process.env.REACT_APP_ENV !== "prod" &&
      console.log("Iniciando form submit...");
    const { payload } = await dispatch(fetchInvoiceList());

    process.env.REACT_APP_ENV !== "prod" &&
      console.log("Payload de busqueda: ", payload);

    process.env.REACT_APP_ENV !== "prod" &&
      console.log("Terminando form submit...");
  };

  const handleInputFechaInicio = (fechaInicio: Date) => {
    setFechaInicioError("");
    setFechaFinError("");
    setMaxDate(
      moment(fechaInicio).add(30, "days").isSameOrAfter(currentDate)
        ? currentDate
        : moment(fechaInicio).add(30, "days").toDate()
    );

    dispatch(
      setFechaInicio({
        fechaInicio: moment(fechaInicio).format("YYYY-MM-DD"),
      })
    );
    dispatch(
      setFechaFin({
        fechaFin: moment(fechaInicio).add(30, "days").isSameOrAfter(currentDate)
          ? moment(currentDate).format("YYYY-MM-DD")
          : moment(fechaInicio).add(30, "days").format("YYYY-MM-DD"),
      })
    );

    process.env.REACT_APP_ENV !== "prod" &&
      console.log(
        "Fecha inicio: ",
        fechaInicio,
        "maxima fecha input fecha termino: ",
        moment(fechaInicio).add(30, "days").isSameOrAfter(currentDate)
          ? currentDate
          : moment(fechaInicio).add(30, "days").format("YYYY-MM-DD")
      );
  };

  const handleInputFechaFin = (fechaFin: Date) => {
    process.env.REACT_APP_ENV !== "prod" &&
      console.log("Fecha fin: ", fechaFin);
    setFechaFinError("");

    if (moment(fechaFin).isAfter(maxDate)) {
      setFechaFinError(elements.msg_warning.value);
      return dispatch(
        setFechaFin({ fechaFin: moment(maxDate).format("YYYY-MM-DD") })
      );
    }
    return dispatch(
      setFechaFin({ fechaFin: moment(fechaFin).format("YYYY-MM-DD") })
    );
  };

  const handleInputTipoDte = (tipoDTE: string) => {
    process.env.REACT_APP_ENV !== "prod" &&
      console.log("Tipo documento: ", tipoDTE);
    setTipoDteError("");
    dispatch(setTipoDte({ tipoDTE }));
  };

  const handleInputNroFolio = (folio: string) => {
    // reset input errors
    setFechaFinError("");
    setFechaInicioError("");
    setTipoDteError("");

    // Regex for non numeric values
    const nonNumRegex = /\D/g;

    // Set invoice number replacing non numeric values
    dispatch(
      setInvoiceNumber({
        invoiceNumber: folio.replace(nonNumRegex, ""),
      })
    );
    dispatch(setFechaInicio({ fechaInicio: "" }));
    dispatch(setFechaFin({ fechaFin: "" }));
    dispatch(setTipoDte({ tipoDTE: "" }));
  };

  const handleClearFilters = () => {
    process.env.REACT_APP_ENV !== "prod" && console.log("Limpiando filtros...");
    // reset input errors
    setTipoDteError("");
    setFechaFinError("");
    setFechaInicioError("");

    dispatch(clearFilters());
    $("input[type=date]").val("");
  };

  const customHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: {
    date: Date;
    decreaseMonth(): void;
    increaseMonth(): void;
    prevMonthButtonDisabled: boolean;
    nextMonthButtonDisabled: boolean;
  }) => {
    // Get the month name (e.g., "July")
    const monthName = date.toLocaleString(calendarLanguage, {
      month: "long",
    });

    // Get the year (e.g., "2023")
    const year = date.getFullYear();

    return (
      <div className="react-datepicker__header">
        <button
          disabled={prevMonthButtonDisabled}
          onClick={decreaseMonth}
          type="button"
          className="react-datepicker__navigation react-datepicker__navigation--previous"
          aria-label="Previous Month"
        >
          <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
            Previous Month
          </span>
        </button>
        <button
          disabled={nextMonthButtonDisabled}
          onClick={increaseMonth}
          type="button"
          className="react-datepicker__navigation react-datepicker__navigation--next"
          aria-label="Next Month"
        >
          <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
            Next Month
          </span>
        </button>
        <div className="react-datepicker__current-month">
          <span style={{ fontWeight: 700 }}>{monthName}</span> {year}
        </div>
      </div>
    );
  };

  const formatDate = (date: number) => {
    const formattedDay = String(date).padStart(2, "0");
    return formattedDay;
  };

  return (
    <form className="needs-validation mb-4" onSubmit={handleFormSubmit}>
      <div className="row mb-3 g-3">
        <div className="col-12 col-lg-4">
          <div className="input-group input-group--outline has-validation">
            <span className="input-group-text">
              <InputSearchIcon />
            </span>
            <input
              disabled={loadingStatus === "loading"}
              onChange={(e) => handleInputNroFolio(e.currentTarget.value)}
              value={invoiceState.invoiceNumber}
              type="text"
              onKeyPress={(e) => {
                if (e.key === "Enter") return;
                if (!/[0-9]/.test(e.key)) return e.preventDefault();
              }}
              className="form-control"
              id="search"
              placeholder={elements.txt_field_find.value}
            />
          </div>
        </div>
        <div className="col-12 col-lg-2">
          <div className="input-group input-group--outline has-validation px-1">
            <label htmlFor="startDate" className="custom-label">
              {elements.txt_field_start_date.value}
            </label>
            <DatePicker
              renderCustomHeader={customHeader}
              renderDayContents={(day) => formatDate(day)}
              onChange={(date) => date && handleInputFechaInicio(date)}
              formatWeekDay={(day) => day.substring(0, 3).toUpperCase()}
              showPopperArrow={false}
              popperPlacement="auto"
              locale={language}
              minDate={minCalendarDate}
              maxDate={currentDate}
              disabled={
                invoiceState.invoiceNumber.length > 0 ||
                loadingStatus === "loading"
              }
              dateFormat={"dd/MM/yyyy"}
              selected={
                invoiceState.fechaInicio
                  ? moment(invoiceState.fechaInicio).toDate()
                  : undefined
              }
              className="form-control"
              id="startDate"
              dayClassName={() => "dg-dp-square"}
              placeholderText={elements.txt_start_date_example.value}
              calendarStartDay={language === "es" ? 1 : 0}
            />
          </div>
          {fechaInicioError.length > 0 && (
            <div className="ml-3 text-danger">{fechaInicioError}</div>
          )}
        </div>
        <div className="col-12 col-lg-2">
          <div className="input-group input-group--outline has-validation px-1">
            <label htmlFor="endDate" className="custom-label">
              {elements.txt_field_end_date.value}
            </label>
            <DatePicker
              renderCustomHeader={customHeader}
              renderDayContents={(day) => formatDate(day)}
              calendarStartDay={language === "es" ? 1 : 0}
              formatWeekDay={(day) => day.substring(0, 3).toUpperCase()}
              popperPlacement="auto"
              showPopperArrow={false}
              locale={language}
              dateFormat={"dd/MM/yyyy"}
              disabled={
                !invoiceState.fechaInicio ||
                invoiceState.invoiceNumber.length > 0 ||
                loadingStatus === "loading"
              }
              minDate={moment(invoiceState.fechaInicio).toDate()}
              maxDate={
                moment(maxDate).isSameOrAfter(currentDate)
                  ? currentDate
                  : maxDate
              }
              onChange={(date) => date && handleInputFechaFin(date)}
              selected={
                invoiceState.fechaFin
                  ? moment(invoiceState.fechaFin).toDate()
                  : undefined
              }
              className="form-control"
              id="endDate"
              placeholderText={elements.txt_field_end_date_example.value}
              dayClassName={() => "dg-dp-square"}
            />
          </div>
          {fechaFinError.length > 0 && (
            <div className="text-danger">{fechaFinError}</div>
          )}
        </div>
        <div className="col-12 col-lg-2">
          <div className="input-group input-group--outline has-validation px-1">
            <label
              htmlFor="tipoDTE"
              className="custom-label"
              id="custom-label-dte"
            >
              {elements.column_document_type.value}
            </label>
            <select
              disabled={
                invoiceState.invoiceNumber.length > 0 ||
                loadingStatus === "loading"
              }
              className="form-select"
              name="tipoDTE"
              id="tipoDTE"
              value={invoiceState.tipoDTE}
              onChange={(e) => handleInputTipoDte(e.currentTarget.value)}
              style={
                invoiceState.tipoDTE.length === 0
                  ? { color: "#8d8c8c" }
                  : undefined
              }
            >
              <option
                className="form-selected-option"
                value={elements.list_txt0_value.value}
                disabled
              >
                {elements.list_txt0.value}
              </option>
              {documentOptions.map(({ label, value }) => (
                <option
                  key={`${label}-${value}`}
                  value={value}
                  className="form-select-option"
                >
                  {label}
                </option>
              ))}
            </select>
          </div>
          {tipoDteError.length > 0 && (
            <div className="ml-3 text-danger">{tipoDteError}</div>
          )}
        </div>
        <div className="col-12 col-lg-2">
          <div className="row">
            <div className="col-8 col-lg-12 d-flex justify-content-center align-items-center">
              <button
                disabled={loadingStatus === "loading"}
                style={{ maxHeight: "45px", width: "100%" }}
                id="searchTable"
                type="submit"
                className="btn btn-sm btn-info btn-search d-inline-block mx-auto"
              >
                <span className="d-flex align-items-center justify-content-center">
                  <span className="ms-3 me-2 fs-6">
                    {elements.btn_find_title.value}
                  </span>
                  <IconNavigationWhite />
                </span>
              </button>
            </div>
            <div className="col-4 col-lg-12 d-flex justify-content-center align-items-center">
              <div
                className="btn btn-link px-0"
                id="clear-filter-btn"
                onClick={handleClearFilters}
              >
                {elements.reset_filters.value}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row d-flex align-items-center justify-content-center">
        <div className="col-12 col-sm-5 bg-info ">
          <div className="text-center">{elements.msg_txt_3.value}</div>
        </div>
      </div>
    </form>
  );
};

export default Form;
