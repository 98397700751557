import moment from "moment";
import { utils, writeFile } from "xlsx";
import { Invoice } from "../../../@types/Invoice";
import { selectMainContentType } from "../../../redux/contentType/contentTypeSlice";
import { useAppSelector } from "../../../redux/hooks";
import {
  selectDocumentTypes,
  selectInvoiceList,
  selectInvoiceState
} from "../../../redux/invoices/invoicesSlice";

const ExcelExport = () => {
  const invoiceState = useAppSelector(selectInvoiceState);
  const invoiceList = useAppSelector(selectInvoiceList);
  const documentTypes = useAppSelector(selectDocumentTypes);
  const {
    item: { elements },
  } = useAppSelector(selectMainContentType);

  const handleExcelExport = () => {
    const listWithoutURL = invoiceList.map(
      (invoice: Invoice, index: number) => {
        // Remove URL atribute from Invoice Object
        const { URL, ...rest } = invoice;
        // Obtain Tipo DTE Text from dte code
        const tipoDteLabel = documentTypes.find(
          ({ value }) => value === invoice.TipoDTE
        )?.label;

        // Return Invoice Object with legible atribute Keys
        return {
          [elements.list_number.value]: index + 1,
          [elements.column_document_type.value]: tipoDteLabel,
          [elements.column_document_number.value]: rest.Folio,
          [elements.column_date_issue.value]: moment(
            rest.FechEmis,
            "DD/MM/YYYY"
          ).format("DD/MM/YYYY"),
          [elements.column_receiver_identifier.value]: rest.RUTReceptor,
          [elements.column_receiver_name.value]: rest.RazonSocialReceptor,
          [elements.column_net.value]: rest.Neto,
          [elements.column_exempt.value]: rest.Exento,
          [elements.column_vat.value]: rest.IVA,
          [elements.column_total.value]: rest.Total,
          [elements.column_state.value]: rest.EstadoSII,
        };
      }
    );

    process.env.REACT_APP_ENV !== "prod" &&
      console.log("Lista de facturas a excel:  ", listWithoutURL);

    // Create an Excel WorkSheet from listWithoutURL
    const ws = utils.json_to_sheet(listWithoutURL);
    // Create an Excel Workbook
    const wb = utils.book_new();
    // Append worksheet to workbook
    utils.book_append_sheet(wb, ws);

    const filename =
      invoiceState.fechaInicio && invoiceState.fechaFin
        ? invoiceState.fechaInicio +
          "_" +
          invoiceState.fechaFin +
          "_" +
          invoiceList[0].RUTReceptor +
          ".xlsx"
        : invoiceList[0].RUTReceptor + ".xlsx";

    // // Create and export excel file
    return writeFile(wb, filename);
  };
  return (
    <div className="d-flex" onClick={handleExcelExport}>
      <div
        className="btn btn-info d-flex ms-auto align-items-center mx-3 fs-6"
        id="excelExport"
      >
        {elements.btn_download_excel_title.value}
      </div>
    </div>
  );
};

export default ExcelExport;
