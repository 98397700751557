import { Invoice } from "../../../@types/Invoice";
import TableRow from "./TableRow";

interface Props {
  invoiceList: Invoice[];
}

const TableBody = ({ invoiceList }: Props) => {
  return (
    <tbody>
      {invoiceList.map((invoice: Invoice, index: number) => (
        <TableRow key={index} invoice={invoice} index={index} />
      ))}
    </tbody>
  );
};

export default TableBody;
