import BottomNavbar from "./components/BottomNavbar";
import MobileNavbar from "./components/MobileNavbar";
import TopNavbar from "./components/TopNavbar";
const Header = () => {
  return (
    <header className="header">
      <div className="d-none d-md-block">
        <TopNavbar />
        <BottomNavbar />
      </div>
      <div className="d-block d-md-none">
        <MobileNavbar />
      </div>
    </header>
  );
};

export default Header;
