const KontentDelivery = require("@kontent-ai/delivery-sdk");
const projectId = process.env.REACT_APP_KENTICO_ID || "";
const previewApiKey = process.env.REACT_APP_KENTICO_PREVIEW_TOKEN || "";

const getContentType = async (
  contentTag: string,
  culture = process.env.REACT_APP_DEFAULT_CULTURE
) => {
  // const url = `${baseUrl}/${projectId}/items/${contentTag}?language=${culture}`;

  const deliveryClient = KontentDelivery.createDeliveryClient({
    projectId,
    previewApiKey,
    defaultQueryConfig: {
      usePreviewMode: process.env.REACT_APP_ENV !== "prod", // Queries the Delivery Preview API if not Production
    },
  });

  const { response } = await deliveryClient
    .item(contentTag)
    .languageParameter(culture)
    .toPromise();

  return response.data;

  // const headers = {
  //   "Content-Type": "application/json",
  //   Accept: "*/*",
  //   Authorization: `Bearer ${previewApiKey}`,
  // };

  // if (process.env.REACT_APP_ENV !== "prod") {
  //   return axios.get(url, { headers });
  // } else {
  //   return axios.get(url);
  // }
};

export default getContentType;
