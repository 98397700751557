import { selectMainContentType } from "../../../redux/contentType/contentTypeSlice";
import { useAppSelector } from "../../../redux/hooks";
import NavbarHelper from "./NavbarHelper";

const BottomNavbar = () => {
  const {
    item: { elements },
  } = useAppSelector(selectMainContentType);
  return (
    <nav className="navbar navbar-expand-lg bg-primary">
      <div className="container">
        <a href={elements.portal_empresa_item1_url.value}>
          <img
            src={elements?.logo_desktop?.value[0]?.url || ""}
            className="img-fluid"
            alt="Logo"
          />
        </a>
        <NavbarHelper />
      </div>
    </nav>
  );
};

export default BottomNavbar;
