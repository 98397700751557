import { selectMainContentType } from "../../../redux/contentType/contentTypeSlice";
import { useAppSelector } from "../../../redux/hooks";

const Banner = () => {
  const mainKenticoData = useAppSelector(selectMainContentType);
  return (
    <div className="">
      <img
        src={mainKenticoData?.item?.elements?.banner?.value[0]?.url || ""}
        className="img-fluid"
        alt="banner-1"
      />
    </div>
  );
};

export default Banner;
