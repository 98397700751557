const InputSearchIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7346 20.1429L14.3434 15.7888C13.6589 16.3583 12.8716 16.8025 11.9816 17.1214C11.0917 17.4403 10.1789 17.5997 9.24329 17.5997C6.89289 17.5997 4.9076 16.7911 3.28741 15.1738C1.66723 13.5565 0.85714 11.5747 0.85714 9.22845C0.85714 6.90498 1.66723 4.92867 3.28741 3.29952C4.9076 1.67127 6.89289 0.857147 9.24329 0.857147C11.5709 0.857147 13.5448 1.6658 15.1649 3.28312C16.7851 4.90043 17.5952 6.8822 17.5952 9.22845C17.5952 10.2079 17.4355 11.1419 17.116 12.0303C16.7965 12.9187 16.363 13.6931 15.8153 14.3537L20.1429 18.7371L18.7346 20.1429ZM9.24329 15.5496C11.0004 15.5496 12.4896 14.9346 13.7109 13.7045C14.9313 12.4745 15.5415 10.9824 15.5415 9.22845C15.5415 7.47446 14.9313 5.98243 13.7109 4.75236C12.4896 3.5223 11.0004 2.90726 9.24329 2.90726C7.46337 2.90726 5.96322 3.5223 4.74284 4.75236C3.52154 5.98243 2.91089 7.47446 2.91089 9.22845C2.91089 10.9824 3.52154 12.4745 4.74284 13.7045C5.96322 14.9346 7.46337 15.5496 9.24329 15.5496Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default InputSearchIcon;
