import React from "react";

const NextLabel = () => {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.02749 13.072C0.745363 13.072 0.463236 12.9437 0.261719 12.6869C-0.121166 12.2377 -0.0808626 11.5531 0.362477 11.168L5.42058 6.56828L0.362477 1.96859C-0.0607108 1.5835 -0.121166 0.898897 0.261719 0.449625C0.624451 0.000352599 1.26931 -0.0638289 1.6925 0.342655L7.65743 5.75531C7.85895 5.94786 8.00001 6.24738 8.00001 6.56828C8.00001 6.88919 7.85894 7.18871 7.63727 7.38125L1.6925 12.8153C1.49098 12.9865 1.24916 13.072 1.02749 13.072Z"
        fill="#BDBDBD"
      />
    </svg>
  );
};

export default NextLabel;
