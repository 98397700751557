import { Helmet } from "react-helmet-async";
import { Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Main from "./components/Main";
import Modal from "./components/Modal";
import OnErrorReroute from "./components/OnErrorReroute";
import Reroute from "./components/Reroute";
import {
  selectContentTypeLoadingStatus,
  selectIsCultureVisible,
  selectMainContentType,
} from "./redux/contentType/contentTypeSlice";
import { useAppSelector } from "./redux/hooks";
import Footer from "./components/Footer";

function App() {
  const appRouteName = process.env.REACT_APP_ROUTE_NAME;
  const loadingStatus = useAppSelector(selectContentTypeLoadingStatus);
  const mainKenticoData = useAppSelector(selectMainContentType);
  const isCultureVisible = useAppSelector(selectIsCultureVisible);
  
  console.log("Version: 1.1.0");

  if (loadingStatus === "idle") {
    return (
      <Routes>
        <Route
          path={`/:country/:language/${appRouteName}/:companyIdentifier`}
          element={<Reroute />}
        />
        <Route path="*" element={<OnErrorReroute />} />
      </Routes>
    );
  }

  if (loadingStatus === "fulfilled") {
    return (
      <div className="bg-body min-vh-100">
        <Helmet>
          <title>
            {mainKenticoData.item.elements.metadata__title.value || ""}
          </title>
          <meta
            name="description"
            content={
              mainKenticoData.item.elements.metadata__description.value || ""
            }
          />
        </Helmet>
        <Header />
        {"yes" !== isCultureVisible ? null : (
          <Routes>
            <Route
              path={`/:country/:language/${appRouteName}`}
              element={<Main />}
            />
          </Routes>
        )}
        <Footer />
        <Modal />
      </div>
    );
  }
  return null;
}

export default App;
