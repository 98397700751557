import { selectMainContentType } from "../../../redux/contentType/contentTypeSlice";
import { useAppSelector } from "../../../redux/hooks";

const NavbarHelper = () => {
  const {
    item: { elements },
  } = useAppSelector(selectMainContentType);
  return (
    <>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarMain"
        aria-controls="navbarMain"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarMain">
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <li className="nav-item dropdown">
            <div
              className="nav-link dropdown-toggle active"
              aria-current="page"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {elements.what_is_portal_empresas.value}
            </div>
            <ul className="dropdown-menu">
              <li>
                <a
                  className="dropdown-item"
                  href={elements.portal_empresa_item1_url.value}
                >
                  {elements.portal_empresa_item1_text.value}
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href={elements.portal_empresa_item2_url.value}
                >
                  {elements.portal_empresa_item2_text.value}
                </a>
              </li>
              {/* <li>
                  <hr className="dropdown-divider" />
                </li> */}
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
};

export default NavbarHelper;
