import { useEffect } from "react";
import TagManager from "react-gtm-module";
import { Navigate, useParams } from "react-router-dom";
import { getKenticoData } from "../../redux/contentType/contentTypeSlice";
import { useAppDispatch } from "../../redux/hooks";
import { setCompanyIdentifier } from "../../redux/invoices/invoicesSlice";

const Reroute = () => {
  const { country, language, companyIdentifier } = useParams();
  const appRouteName = process.env.REACT_APP_ROUTE_NAME;
  const gtmId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Initialize Google Tag Manager
    if (gtmId) {
      TagManager.initialize({
        gtmId,
      });
    }
  }, [gtmId]);

  useEffect(() => {
    dispatch(getKenticoData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      setCompanyIdentifier({
        idFiscalReceptor: companyIdentifier || "",
      })
    );
  }, [companyIdentifier, dispatch]);

  return <Navigate to={`/${country}/${language}/${appRouteName}`} replace />;
};

export default Reroute;
