import React from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import registerServiceWorker from "./serviceWorker";
const container = document.getElementById("root")!;
const root = createRoot(container);

const loadTealium = () => {
  const script = document.createElement("script");
  script.src = process.env.REACT_APP_TEALIUM_UTAG_URL || "";
  script.async = true;
  document.head.appendChild(script);
};

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HelmetProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </HelmetProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
loadTealium();
reportWebVitals();
registerServiceWorker();
