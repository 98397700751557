import { useAppSelector } from "../../redux/hooks";
import {
  selectInvoiceDownloadStatus,
  selectInvoiceLoadingStatus
} from "../../redux/invoices/invoicesSlice";

const Loader = () => {
  const loadingStatus = useAppSelector(selectInvoiceLoadingStatus);
  const invoiceDownloadStatus = useAppSelector(selectInvoiceDownloadStatus);
  return (
    <>
      {loadingStatus === "loading" || invoiceDownloadStatus === "loading" ? (
        <div className="loading">
          <div className="loading__content">
            <svg
              className="loading__symbol"
              viewBox="0 0 180 180"
              xmlns="http://www.w3.org/2000/svg"
              width="110px"
              height="110px"
              style={{ visibility: "visible" }}
            >
              <defs>
                <circle
                  className="RTdot"
                  cx="0"
                  cy="0"
                  r="5"
                  fill="#163a70"
                ></circle>
              </defs>
              <circle
                className="RTmainCircle"
                fill="none"
                stroke="none"
                strokeWidth="2"
                strokeMiterlimit="10"
                cx="90"
                cy="90"
                r="70"
              ></circle>
              <path
                strokeMiterlimit="10"
                strokeWidth="2"
                stroke="none"
                fill="none"
                className="RTcirclePath"
                d="M160 90 C160 128.65 128.65 160 90 160 51.34 160 20 128.65 20 90 20 51.34 51.34 20 90 20 128.65 20 160 51.34 160 90 z"
              ></path>
              <g
                className="RTmainContainer"
                data-svg-origin="90 90"
                transform="matrix(0.55478,-0.83199,0.83199,0.55478,-34.810128026343676,114.9485859729104)"
                style={{ backfaceVisibility: "hidden", zIndex: "0" }}
              >
                <path
                  className="RTplane"
                  fill="#163a70"
                  d="M38.1,19.6c0.2-0.2,0.3-0.5,0.3-0.7s-0.1-0.6-0.3-0.7c-1.2-1-2.8-1.6-4.4-1.6l-8.7,0L12.2,0L8.2,0l6.3,16.5	l-5.9,0c-0.5,0-1.1,0.1-1.6,0.4L3.1,11L0,11l3.1,7.9L0,26.7l3.1,0l3.9-5.9c0.5,0.3,1,0.4,1.6,0.4l5.9,0L8.2,37.7h3.9l13-16.5l8.7,0	C35.4,21.2,36.9,20.6,38.1,19.6"
                  data-svg-origin="19.19999885559082 18.850000381469727"
                  transform="matrix(0.95819,-0.28611,0.28611,0.95819,46.349492272934576,11.197984683780648)"
                  style={{ backfaceVisibility: "hidden", zIndex: "0" }}
                ></path>
                <circle
                  className="RTdot"
                  cx="160"
                  cy="90"
                  r="0.13680801185184688"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="158.87507120190406"
                  cy="102.49898263590457"
                  r="0.1847622271793643"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="155.5364409447816"
                  cy="114.59623768569399"
                  r="0.24278686926665038"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="150.09141555213063"
                  cy="125.90294941841344"
                  r="0.31184175766762934"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="142.71500262025276"
                  cy="136.0557108157799"
                  r="0.3928867119362254"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="133.64428613011137"
                  cy="144.72820377276207"
                  r="0.48688155162636093"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="123.1708063731099"
                  cy="151.64168722997167"
                  r="0.594786096291962"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="111.63118960624632"
                  cy="156.57395614066075"
                  r="0.7175601654869501"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="99.39632860723589"
                  cy="159.36648332375543"
                  r="0.8561635787652513"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="86.85946187546395"
                  cy="159.929514657892"
                  r="1.011556155680788"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="74.423534623058"
                  cy="158.24495385272763"
                  r="1.1846977157874843"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="62.48824778422534"
                  cy="154.36694407860153"
                  r="1.3765480786392645"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="51.4372112983528"
                  cy="148.42012776049117"
                  r="1.5880670637900525"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="41.625614570919474"
                  cy="140.5956404679174"
                  r="1.820214490793771"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="33.368810393753684"
                  cy="131.14496766047313"
                  r="2.073950179204347"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="26.93217924683065"
                  cy="120.37186173822906"
                  r="2.350233948575701"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="22.522599751290272"
                  cy="108.62257918966726"
                  r="2.6500256184617568"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="20.28179942033327"
                  cy="96.27475162324035"
                  r="2.9742850084164396"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="20.28179942033327"
                  cy="83.72524837675964"
                  r="3.3239719379937185"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="22.522599751290272"
                  cy="71.37742081033275"
                  r="3.7000462267474292"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="26.932179246830657"
                  cy="59.62813826177094"
                  r="4.103467694231539"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="33.36881039375369"
                  cy="48.855032339526865"
                  r="4.5351961599999715"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="41.62561457091949"
                  cy="39.40435953208258"
                  r="4.99619144360665"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="51.437211298352814"
                  cy="31.579872239508823"
                  r="1.839706182157589"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="62.48824778422539"
                  cy="25.633055921398437"
                  r="0.3999170276385424"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="74.42353462305805"
                  cy="21.75504614727234"
                  r="0.015288222973767418"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="86.85946187546395"
                  cy="20.070485342107972"
                  r="0.000019051696360961046"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="99.39632860723587"
                  cy="20.633516676244568"
                  r="0.0005313061125304941"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="111.63118960624631"
                  cy="23.426043859339245"
                  r="0.002475611303155212"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="123.17080637310995"
                  cy="28.358312770028363"
                  r="0.006811786822156662"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="133.64428613011137"
                  cy="35.27179622723794"
                  r="0.014499652223460835"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="142.7150026202528"
                  cy="43.94428918422015"
                  r="0.026499027060991054"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="150.09141555213063"
                  cy="54.09705058158656"
                  r="0.043769730888670644"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="155.53644094478162"
                  cy="65.40376231430605"
                  r="0.0672715832604247"
                  fill="#163a70"
                ></circle>
                <circle
                  className="RTdot"
                  cx="158.87507120190406"
                  cy="77.50101736409542"
                  r="0.09796440373017568"
                  fill="#163a70"
                ></circle>
              </g>
            </svg>
            <img
              className="loading__logo"
              src="https://booking.jetsmart.com/Images/Header/jetsmart-logo-colored.svg"
              alt="loading"
            />
          </div>
          <div className="loading__overlay"></div>
        </div>
      ) : null}
    </>
  );
};

export default Loader;
