import { selectMainContentType } from "../../../redux/contentType/contentTypeSlice";
import { useAppSelector } from "../../../redux/hooks";
import CultureDropdown from "./CultureDropdown";
const TopNavbar = () => {
  const {
    item: { elements },
  } = useAppSelector(selectMainContentType);
  return (
    <nav className="navbar navbar-expand-sm bg-light">
      <div className="container">
        <div className="collapse navbar-collapse" id="navbarTop">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a
                className="nav-link py-2 px-3"
                href={elements.jetsmart_url.value}
              >
                <span className="d-flex my-2">
                  {elements.jetsmart_url_label.value}
                </span>
              </a>
            </li>
            <CultureDropdown />
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default TopNavbar;
