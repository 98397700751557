import { selectMainContentType } from "../../../redux/contentType/contentTypeSlice";
import { useAppSelector } from "../../../redux/hooks";

const TableHead = () => {
  const {
    item: { elements },
  } = useAppSelector(selectMainContentType);

  const tableHeadItems: string[] = [
    elements.list_number.value,
    elements.column_document_type.value,
    elements.column_document_number.value,
    elements.column_date_issue.value,
    elements.column_receiver_identifier.value,
    elements.column_receiver_name.value,
    elements.column_net.value,
    elements.column_exempt.value,
    elements.column_vat.value,
    elements.column_total.value,
    elements.column_state.value,
    elements.column_documento_download.value,
  ];
  return (
    <thead className="table-dark">
      <tr>
        {tableHeadItems.map((item, index) => (
          <th key={`${item}-${index}`}>
            <div className="fw-light">{item}</div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;
