import { useEffect, useRef } from "react";
import { Invoice } from "../../@types/Invoice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  selectInvoiceState,
  setShowDownloadModal,
} from "../../redux/invoices/invoicesSlice";

const Modal = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { showDownloadModal, invoiceInModal } =
    useAppSelector(selectInvoiceState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    showDownloadModal && buttonRef?.current?.click();
  }, [showDownloadModal]);

  useEffect(() => {
    $("#downloadModal").on("hidden.bs.modal", function () {
      dispatch(
        setShowDownloadModal({
          showDownloadModal: false,
          invoice: {} as Invoice,
        })
      );
    });
  }, [dispatch]);

  return (
    <>
      <button
        onClick={() => $("#downloadModal").modal("show")}
        ref={buttonRef}
        type="button"
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#downloadModal"
      >
        Descargar
      </button>
      <div
        className="modal fade"
        id="downloadModal"
        tabIndex={-1}
        aria-labelledby="downloadModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <p className="modal-title" id="downloadModalLabel"></p>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => $("#downloadModal").modal("hide")}
              ></button>
            </div>
            <div className="modal-body d-flex flex-column text-center align-items-center px-4">
              <svg
                width="112"
                height="112"
                viewBox="0 0 112 112"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M28 84H84V79.1778H28V84ZM55.8444 63.6222L76.2222 43.4L72.8 39.9778L58.1778 54.6V21.7778H53.3556V54.6L38.8889 39.9778L35.4667 43.4L55.8444 63.6222ZM56 112C48.3259 112 41.0926 110.522 34.3 107.567C27.5074 104.611 21.5704 100.593 16.4889 95.5111C11.4074 90.4296 7.38889 84.5185 4.43333 77.7778C1.47778 71.037 0 63.7778 0 56C0 48.2222 1.47778 40.937 4.43333 34.1444C7.38889 27.3519 11.4074 21.4407 16.4889 16.4111C21.5704 11.3815 27.4815 7.38889 34.2222 4.43333C40.963 1.47778 48.2222 0 56 0C63.7778 0 71.063 1.47778 77.8556 4.43333C84.6481 7.38889 90.5593 11.3815 95.5889 16.4111C100.619 21.4407 104.611 27.3519 107.567 34.1444C110.522 40.937 112 48.2222 112 56C112 63.6741 110.522 70.9074 107.567 77.7C104.611 84.4926 100.619 90.4296 95.5889 95.5111C90.5593 100.593 84.6481 104.611 77.8556 107.567C71.063 110.522 63.7778 112 56 112ZM56 107.178C70.2074 107.178 82.2889 102.2 92.2445 92.2445C102.2 82.2889 107.178 70.2074 107.178 56C107.178 41.7926 102.2 29.7111 92.2445 19.7556C82.2889 9.8 70.2074 4.82222 56 4.82222C41.7926 4.82222 29.7111 9.8 19.7556 19.7556C9.8 29.7111 4.82222 41.7926 4.82222 56C4.82222 70.2074 9.8 82.2889 19.7556 92.2445C29.7111 102.2 41.7926 107.178 56 107.178Z"
                  fill="#263F6A"
                />
              </svg>
              <p className="mt-4 fs-3">
                Factura <b>N° {invoiceInModal.Folio}</b> con fecha{" "}
                <b>{invoiceInModal.FechEmis}</b> fue descargada con éxito
              </p>
            </div>
            <div className="modal-footer d-flex justify-content-center">
              <button
                onClick={() => $("#downloadModal").modal("hide")}
                type="button"
                data-bs-dismiss="modal"
                className="btn btn-info d-flex align-items-center justify-content-between"
                id="modalDismiss"
              >
                <span className="mx-5">Aceptar</span>
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.75 15.8333C0.75 7.50755 7.50755 0.75 15.8333 0.75C24.1591 0.75 30.9167 7.50755 30.9167 15.8333C30.9167 24.1591 24.1591 30.9167 15.8333 30.9167C7.50755 30.9167 0.75 24.1591 0.75 15.8333Z"
                    stroke="currentColor"
                    strokeWidth={1.5}
                  />
                  <path
                    d="M12.5067 23.75C12.1159 23.75 11.725 23.5936 11.4458 23.281C10.9154 22.7338 10.9712 21.9 11.5854 21.4309L18.593 15.8288L11.5854 10.2266C10.9991 9.75755 10.9154 8.92373 11.4458 8.37654C11.9484 7.82935 12.8418 7.75118 13.4281 8.24626L21.692 14.8386C21.9712 15.0731 22.1666 15.4379 22.1666 15.8288C22.1666 16.2196 21.9712 16.5844 21.664 16.8189L13.4281 23.4373C13.1489 23.6458 12.8138 23.75 12.5067 23.75Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
