import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface cultureState {
  country: string;
  language: string;
  culture: string;
  status: "idle" | "loading" | "failed";
}

const initialState: cultureState = {
  country: process.env.REACT_APP_DEFAULT_COUNTRY || "",
  language: process.env.REACT_APP_DEFAULT_LANGUAGE || "",
  culture: process.env.REACT_APP_DEFAULT_CULTURE || "",
  status: "idle",
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const incrementAsync = createAsyncThunk(
  "counter/fetchCount",
  async (amount: number) => {
    // const response = await fetchCount(amount);
    // The value we return becomes the `fulfilled` action payload
    // return response.data;
  }
);

export const cultureSlice = createSlice({
  name: "culture",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setCultureData: (
      state,
      action: PayloadAction<{
        country: string;
        language: string;
        culture: string;
      }>
    ) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.country = action.payload.country;
      state.language = action.payload.language;
      state.culture = action.payload.culture;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {},
});

export const { setCultureData } = cultureSlice.actions;
export const selectCultureStore = (state: RootState) => state.culture;
export const selectCultureLanguage = (state: RootState) =>
  state.culture.language;
export const selectCalendarLanguage = (state: RootState) =>
  `${state.culture.language}-${state.culture.country.toUpperCase()}`;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState) => state.culture.value;

// We can also write thunks by hand, which may contain both sync and async logic.

export default cultureSlice.reducer;
