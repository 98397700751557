import axios from "axios";
import { useState } from "react";
import { Invoice } from "../../../@types/Invoice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  selectDocumentTypes,
  selectInvoiceList,
  setInvoiceLoadingStatus,
  setShowDownloadModal,
} from "../../../redux/invoices/invoicesSlice";

import moment from "moment";
import downloadImageHover from "../images/download-hover.png";
import downloadImageIdle from "../images/download-idle.png";

interface Props {
  invoice: Invoice;
  index: number;
}

const TableRow = ({ invoice, index }: Props) => {
  const dispatch = useAppDispatch();
  const documentTypes = useAppSelector(selectDocumentTypes);
  const tipoDteLabel = documentTypes.find(
    ({ value }) => value === invoice.TipoDTE
  )?.label;

  const apiEndpoint = process.env.REACT_APP_BACKEND_API_ENDPOINT;
  const apiKey = process.env.REACT_APP_BACKEND_API_KEY;
  const invoiceList = useAppSelector(selectInvoiceList);
  const [downloadHover, setDownloadHover] = useState<boolean>(false);
  const getPdf = async () => {
    dispatch(setInvoiceLoadingStatus({ status: "loading" }));
    const URL = apiEndpoint + invoice.URL;
    const { data } = await axios.get(URL, {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "X-Api-Key": `${apiKey}`,
      },
    });
    process.env.REACT_APP_ENV !== "prod" &&
      console.log("Axios data getPDF: ", data);

    if (data.pdfBase64) {
      const linkSource = `data:application/pdf;base64,${data.pdfBase64}`;
      const downloadLink = document.createElement("a");
      const fileName = invoice.Folio + ".pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();

      dispatch(
        setShowDownloadModal({
          showDownloadModal: true,
          invoice,
        })
      );
    }

    return dispatch(setInvoiceLoadingStatus({ status: "success" }));
  };

  return (
    <tr>
      <td>
        <div className="py-2 d-inline-block lh-1 fs-6 text-primary">
          {invoiceList.indexOf(invoice) + 1}
        </div>
      </td>
      <td>
        <div className="py-2 d-inline-block lh-1 fs-6 text-primary">
          {tipoDteLabel || ""}
        </div>
      </td>
      <td>
        <span className="py-2 d-inline-block lh-1 fs-6 text-primary">
          {invoice.Folio}
        </span>
      </td>
      <td>
        <div className="py-2 d-inline-block lh-1 fs-6 text-primary">
          {moment(invoice.FechEmis, "DD/MM/YYYY").format("DD/MM/YYYY")}
        </div>
      </td>
      <td>
        <div
          className="py-2 d-inline-block lh-1 fs-6 text-primary"
          style={{ whiteSpace: "nowrap" }}
        >
          {invoice.RUTReceptor}
        </div>
      </td>
      <td>
        <span className="py-2 d-inline-block lh-1 fs-6 text-primary">
          {invoice.RazonSocialReceptor}
        </span>
      </td>
      <td>
        <div className="py-2 d-inline-block lh-1 fs-6 text-primary">
          {invoice.Neto === "null" ? 0 : invoice.Neto}
        </div>
      </td>
      <td>
        <div className="py-2 d-inline-block lh-1 fs-6 text-primary">
          {invoice.Exento === "null" ? 0 : invoice.Exento}
        </div>
      </td>
      <td>
        <div className="py-2 d-inline-block lh-1 fs-6 text-primary">
          {invoice.IVA === "null" ? 0 : invoice.IVA}
        </div>
      </td>
      <td>
        <span className="py-2 d-inline-block lh-1 fs-6 text-primary">
          {invoice.Total === "null" ? 0 : invoice.Total}
        </span>
      </td>
      <td>
        <div className="py-2 d-inline-block lh-1 fs-6 text-primary">
          {invoice.EstadoSII}
        </div>
      </td>
      <td>
        <div
          className="btn d-inline-block mx-auto"
          id="download-pdf-btn"
          onClick={() => getPdf()}
          onMouseOver={() => setDownloadHover(true)}
          onMouseOut={() => setDownloadHover(false)}
        >
          <img
            src={downloadHover ? downloadImageHover : downloadImageIdle}
            className="img-fluid"
            alt="download"
          />
        </div>
      </td>
    </tr>
  );
};

export default TableRow;
