import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const OnErrorReroute = () => {
  const appRouteName = process.env.REACT_APP_ROUTE_NAME || "";
  const country = process.env.REACT_APP_DEFAULT_COUNTRY || "";
  const language = process.env.REACT_APP_DEFAULT_LANGUAGE || "";
  const [companyToken, setCompanyToken] = useState("");

  useEffect(() => {
    const getWithExpiry = () => {
      const key = "companyIdentifier";
      const itemStr = localStorage.getItem(key);

      // if the itemStr doesn't exist, return null
      if (!itemStr) {
        return null;
      }
      const item = JSON.parse(itemStr);

      const now = new Date();

      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key);
        return null;
      }
      return item.value;
    };

    const token = getWithExpiry();

    if (!token) {
      window.location.href = process.env.REACT_APP_REDIRECT_URL || "";
      return;
    }
    setCompanyToken(token);
  }, []);

  if (!companyToken) return null;

  return (
    <Navigate
      to={`/${country}/${language}/${appRouteName}/${companyToken}`}
      replace
    />
  );
};

export default OnErrorReroute;
