import { selectMainContentType } from "../../../redux/contentType/contentTypeSlice";
import { useAppSelector } from "../../../redux/hooks";

const Header = () => {
  const {
    item: { elements },
  } = useAppSelector(selectMainContentType);

  return (
    <>
      <h3 className="fs-5 d-flex f-title">
        <span>
          <svg
            width="36"
            height="39"
            viewBox="0 0 36 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0V36.2143H22.1538V33.4286H2.76923V2.78571H16.6154V11.1429H24.9231V13.9286H27.6923V9.19286L18.5538 0H0ZM19.3846 4.73571L22.9846 8.35714H19.3846V4.73571ZM5.53846 13.9286V16.7143H22.1538V13.9286H5.53846ZM29.0769 16.7143V19.5C26.7231 19.9179 24.9231 21.8679 24.9231 24.375C24.9231 27.1607 27 29.25 29.7692 29.25H31.1538C32.2615 29.25 33.2308 30.225 33.2308 31.3393C33.2308 32.4536 32.2615 33.4286 31.1538 33.4286H26.3077V36.2143H29.0769V39H31.8462V36.2143C34.2 35.7964 36 33.8464 36 31.3393C36 28.5536 33.9231 26.4643 31.1538 26.4643H29.7692C28.6615 26.4643 27.6923 25.4893 27.6923 24.375C27.6923 23.2607 28.6615 22.2857 29.7692 22.2857H34.6154V19.5H31.8462V16.7143H29.0769ZM5.53846 20.8929V23.6786H15.2308V20.8929H5.53846ZM18 20.8929V23.6786H22.1538V20.8929H18ZM5.53846 26.4643V29.25H15.2308V26.4643H5.53846ZM18 26.4643V29.25H22.1538V26.4643H18Z"
              fill="currentColor"
            />
          </svg>
        </span>
        <span className="d-flex flex-column ms-2">
          <strong>{elements.title_invoices.value}</strong>
          <small className="fw-light">{elements.msg_text_1.value}</small>
        </span>
      </h3>
      <p>{elements.msg_text_2.value}</p>
    </>
  );
};

export default Header;
