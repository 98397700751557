import { selectMainContentType } from "../../../redux/contentType/contentTypeSlice";
import { useAppSelector } from "../../../redux/hooks";

const BackLink = () => {
  const {
    item: { elements },
  } = useAppSelector(selectMainContentType);
  return (
    <a
      href={process.env.REACT_APP_REDIRECT_URL || ""}
      className="btn-link link text-decoration-none text-body my-3 d-inline-block"
    >
      {"<"} <strong>{elements.return_portal_empresa.value}</strong>
    </a>
  );
};

export default BackLink;
