import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useAppDispatch } from "../../redux/hooks";
import Loader from "../Loader";
import BackLink from "./components/BackLink";
import Banner from "./components/Banner";
import Form from "./components/Form";
import MainHeader from "./components/MainHeader";
import Table from "./components/Table";

const Main = () => {
  const dispatch = useAppDispatch();
  const { country, language } = useParams();

  useEffect(() => {
    process.env.REACT_APP_ENV !== "prod" &&
      console.log("Cultura: ", { country, language });
  }, [dispatch, country, language]);

  return (
    <main>
      <Banner />
      <div className="container mb-5">
        <BackLink />
        <section className="card bg-white py-4 px-4">
          <MainHeader />
          <Form />
          <Table />
        </section>
      </div>
      <Loader />
    </main>
  );
};

export default Main;
