import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { DocumentType } from "../../@types/DocumentType";
import { Invoice } from "../../@types/Invoice";
import { getKenticoData } from "../contentType/contentTypeSlice";
import { RootState } from "../store";

export interface invoicesState {
  invoiceList: Invoice[];
  fechaInicio: string;
  fechaFin: string;
  idFiscalReceptor: string;
  tipoDTE: string;
  status: "idle" | "loading" | "failed" | "success";
  invoiceNumber: string;
  showDownloadModal: boolean;
  invoiceInModal: Invoice;
  documentTypes: DocumentType[];
  invoiceDownloadStatus: "idle" | "loading" | "failed" | "success";
}

const initialState: invoicesState = {
  invoiceList: [],
  fechaInicio: "",
  fechaFin: "",
  idFiscalReceptor: "",
  tipoDTE: "",
  status: "idle",
  invoiceNumber: "",
  showDownloadModal: false,
  invoiceInModal: {} as Invoice,
  documentTypes: [],
  invoiceDownloadStatus: "idle",
};

export const fetchInvoiceList = createAsyncThunk(
  "invoices/fetchInvoiceList",
  async (formData, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const {
        fechaInicio,
        fechaFin,
        idFiscalReceptor,
        tipoDTE,
        invoiceNumber,
      } = state.invoices;
      const { culture } = state.culture;
      const apiKey = process.env.REACT_APP_BACKEND_API_KEY;
      const apiEndpoint = process.env.REACT_APP_BACKEND_API_ENDPOINT;
      const headers = {
        "Content-Type": "application/json",
        "X-Api-Key": `${apiKey}`,
      };

      // Refresh Token TTL on every fetch
      const now = new Date();
      const ttl = 1000 * Number(process.env.REACT_APP_COMPANY_TOKEN_TTL) || 0;
      const item = {
        value: state.invoices.idFiscalReceptor,
        expiry: now.getTime() + ttl,
      };
      window.localStorage.setItem("companyIdentifier", JSON.stringify(item));

      const dummyDate = "2022-01-01";

      const URL =
        apiEndpoint +
        `?cultura=${culture}&idFiscalReceptor=${idFiscalReceptor.substring(
          0,
          idFiscalReceptor.length - 2
        )}&tipoDTE=${tipoDTE}&fechaInicio=${
          fechaInicio || dummyDate
        }&fechaFin=${fechaFin || dummyDate}&folio=${invoiceNumber}`;
      const { data } = await axios.get(URL, { headers });
      process.env.REACT_APP_ENV !== "prod" &&
        console.log(data, "Data desde redux");
      return data.Data || [];
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

export const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setCompanyIdentifier: (
      state,
      action: PayloadAction<{ idFiscalReceptor: string }>
    ) => {
      state.idFiscalReceptor = action.payload.idFiscalReceptor;
      const now = new Date();
      const ttl = 1000 * Number(process.env.REACT_APP_COMPANY_TOKEN_TTL) || 0;

      const item = {
        value: action.payload.idFiscalReceptor,
        expiry: now.getTime() + ttl,
      };

      window.localStorage.setItem("companyIdentifier", JSON.stringify(item));
    },
    setTipoDte: (state, action: PayloadAction<{ tipoDTE: string }>) => {
      state.tipoDTE = action.payload.tipoDTE;
    },
    setFechaInicio: (state, action: PayloadAction<{ fechaInicio: string }>) => {
      state.fechaInicio = action.payload.fechaInicio;
      state.fechaFin = "";
    },
    setFechaFin: (state, action: PayloadAction<{ fechaFin: string }>) => {
      state.fechaFin = action.payload.fechaFin;
    },
    setInvoiceNumber: (
      state,
      action: PayloadAction<{ invoiceNumber: string }>
    ) => {
      state.invoiceNumber = action.payload.invoiceNumber;
    },
    setShowDownloadModal: (
      state,
      action: PayloadAction<{ showDownloadModal: boolean; invoice: Invoice }>
    ) => {
      state.showDownloadModal = action.payload.showDownloadModal;
      state.invoiceInModal = action.payload.invoice;
    },
    setInvoiceLoadingStatus: (
      state,
      action: PayloadAction<{
        status: "idle" | "loading" | "failed" | "success";
      }>
    ) => {
      state.invoiceDownloadStatus = action.payload.status;
    },
    clearFilters: (state) => {
      state.fechaInicio = "";
      state.fechaFin = "";
      state.tipoDTE = "";
      state.invoiceNumber = "";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoiceList.pending, (state) => {
        state.status = "loading";
        state.invoiceList = [];
      })
      .addCase(fetchInvoiceList.fulfilled, (state, action) => {
        state.status = "success";
        state.invoiceList = action.payload;
      })
      .addCase(fetchInvoiceList.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getKenticoData.fulfilled, (state, action) => {
        const elements = action.payload.kenticoLanding.item.elements;
        state.documentTypes = [
          {
            label: elements.list_txt1.value,
            value: elements.list_txt1_value.value,
          },
          {
            label: elements.list_txt2.value,
            value: elements.list_txt2_value.value,
          },
          {
            label: elements.list_txt3.value,
            value: elements.list_txt3_value.value,
          },
          {
            label: elements.list_txt4.value,
            value: elements.list_txt4_value.value,
          },
          {
            label: elements.list_txt5.value,
            value: elements.list_txt5_value.value,
          },
        ];
      });
  },
});

export const {
  setFechaFin,
  setFechaInicio,
  setInvoiceNumber,
  setShowDownloadModal,
  setCompanyIdentifier,
  setTipoDte,
  setInvoiceLoadingStatus,
  clearFilters,
} = invoicesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectInvoiceState = (state: RootState) => state.invoices;
export const selectInvoiceList = (state: RootState) =>
  state.invoices.invoiceList;

export const selectInvoiceListHasItems = (state: RootState) =>
  state.invoices.invoiceList.length > 0;
export const selectInvoiceLoadingStatus = (state: RootState) =>
  state.invoices.status;
export const selectDocumentTypes = (state: RootState) =>
  state.invoices.documentTypes;

export const selectInvoiceDownloadStatus = (state: RootState) =>
  state.invoices.invoiceDownloadStatus;

export const selectCompanyIdentifier = (state: RootState) =>
  state.invoices.idFiscalReceptor;
export default invoicesSlice.reducer;
