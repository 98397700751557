const IconNavigationWhite = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.58398 17C3.58398 9.59425 9.59486 3.58337 17.0007 3.58337C24.4064 3.58337 30.4173 9.59425 30.4173 17C30.4173 24.4058 24.4064 30.4167 17.0007 30.4167C9.59486 30.4167 3.58398 24.4058 3.58398 17Z"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M14.0236 24.0834C13.6739 24.0834 13.3242 23.9435 13.0744 23.6638C12.5998 23.1742 12.6498 22.4281 13.1993 22.0085L19.4692 16.996L13.1993 11.9835C12.6747 11.5639 12.5998 10.8178 13.0744 10.3282C13.524 9.83863 14.3234 9.76869 14.848 10.2117L22.242 16.1101C22.4918 16.3199 22.6667 16.6463 22.6667 16.996C22.6667 17.3457 22.4918 17.6721 22.217 17.8819L14.848 23.8036C14.5982 23.9902 14.2984 24.0834 14.0236 24.0834Z"
        fill="white"
      />
      <path
        d="M14.0236 24.0834C13.6739 24.0834 13.3242 23.9435 13.0744 23.6638C12.5998 23.1742 12.6498 22.4281 13.1993 22.0085L19.4692 16.996L13.1993 11.9835C12.6747 11.5639 12.5998 10.8178 13.0744 10.3282C13.524 9.83863 14.3234 9.76869 14.848 10.2117L22.242 16.1101C22.4918 16.3199 22.6667 16.6463 22.6667 16.996C22.6667 17.3457 22.4918 17.6721 22.217 17.8819L14.848 23.8036C14.5982 23.9902 14.2984 24.0834 14.0236 24.0834Z"
        fill="white"
      />
    </svg>
  );
};

export default IconNavigationWhite;
